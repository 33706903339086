import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/layout'
import SEO from '../components/seo'
import '../styles/mes.css'

const MesPage = () => {
  const data = useStaticQuery(graphql`
    query {
      mobileImage: file(relativePath: { eq: "mes.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      desktopImage: file(relativePath: { eq: "mes2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ]

  const scrollToHere = React.createRef()

  const handleClick = () => {
    if (scrollToHere.current) {
      scrollToHere.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
    }
  }

  return (
    <Layout>
      <SEO title="Why MES" />
      <div className="mes-banner">
        <div className="mes-banner_subsection">
          <div className="mes-banner_img">
            <Img alt="Fitness and Spirit" fluid={sources} />
          </div>
        </div>
        <div className="mes-banner_subsection">
          <div className="mes-banner_div">
            <div>
              <h1>What is MES?</h1>
              <p>
                As a Medical Exercise Specialist, I create a Medical Exercise
                Training (MET) program that is safe, effective, structured, and
                protocol-based specific to your medical needs. I focus on
                exercise techniques to enhance your strength, flexibility,
                cardiovascular endurance, balance, stability, and functional
                outcome measures.
              </p>

              <button type="button" onClick={handleClick}>
                LEARN MORE
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container-main">
        <div className="container-main_div" ref={scrollToHere}>
          <h2>More on MES</h2>
          <p>
            Functional outcome measures, FOMs, are yardsticks to measure
            progress that are universal and can easily be interpreted. FOMs
            specific to your protocols will be periodically re-assessed to
            determine exercise effectiveness.
          </p>
          <p>
            As a Medical Exercise Specialist, your MET is specific and directed
            toward resolving your functional deficits. I will equip you with all
            that you require to be the best version of yourself, living a
            functional lifestyle with ease.
          </p>
          <p>
            I filter the below components through anatomy and pathology specific
            to your needs and incorporate them into your Medical Exercise
            Training program:
          </p>
          <ul>
            <li>
              Specific functional outcome measures (FOM) to gauge progress and
              effectiveness
            </li>
            <li>
              Protocol-based approach containing specific exercise progressions
              and documentation/communication guidelines designed for individual
              medical conditions
            </li>
            <li>
              Be finite and provide specific guidelines on frequency and
              duration
            </li>
            <li>Engage clients in home exercise and lifestyle education</li>
          </ul>

          <p className="mt-4">
            <span className="font-semibold">
              All services also include my Lifestyle Fitness Coaching
            </span>
            , which provides an ongoing and guided process of dialogue between
            my client and myself as a health fitness professional. It is
            distinguished from life coaching in such that what I provide is
            within the parameters of the fitness world, while simultaneously
            encompassing the wholeness of my clients. I will provide an emphasis
            in the pursuit of wide-ranging personal and lifestyle desired future
            outcomes through means of rationally defined and intentional planned
            physical activity engagements. Not limiting to my fitness
            certification, I choose to also combine my degree in psychology for
            my lifestyle fitness coaching. I will facilitate change in personal
            skills, character development, psychological and social functioning,
            and other lifestyle and learning intentions. In alignment with my
            mission, I support others in creating their highest choices for
            their optimal health through: fitness, food, fun, and faith.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export default MesPage
